<template>
  <div>
    <ProfileProgressBar
      v-if="member && !isLoading && !isNexos"
      :member-info="member"
      :settings-view="true"
    />
    <div v-if="!isLoading">
      <b-card>
        <!-- Seetings form -->
        <div class="d-flex flex-wrap justify-content-between mt-2">
          <h4>{{ $t("edit-dropdown.details") }}</h4>
          <b-button
            variant="primary"
            type="submit"
            :disabled="isSaving"
            @click="onSubmit"
          >
            {{ $t("form.actions.save") }}
          </b-button>
        </div>
        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="pt-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Name -->
            <b-row class="match-height">
              <b-col lg="6">
                <validation-provider
                  #default="validationContext"
                  :name="$t('members.edit.name.name')"
                  rules="required|max:255"
                >
                  <b-form-group
                    :label="$t('members.edit.name.label')"
                    label-for="profile-name"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="profile-name"
                      v-model="profile.name"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('members.edit.name.placeholder')"
                      :disabled="isSaving"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Surname -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('members.edit.surname.name')"
                  rules="required|max:255"
                >
                  <b-form-group
                    :label="$t('members.edit.surname.label')"
                    label-for="profile-surname"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      id="profile-surname"
                      v-model="profile.surname"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('members.edit.surname.placeholder')"
                      :disabled="isSaving"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Headline -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('members.edit.headline.name')"
                  rules="max:255"
                >
                  <b-form-group>
                    <label for="profile-headline" class="font-weight-bold">
                      {{ $t("members.edit.headline.label") }}
                      <b-button
                        v-if="!individualChat"
                        v-b-tooltip.hover.bottom
                        :title="$t('members.edit.headline.tooltip')"
                        variant="link"
                        class="p-0 link-button mt-n25"
                        @click="isInputDescriptionModalVisible = true"
                      >
                        <feather-icon icon="InfoIcon" size="14" />
                      </b-button>
                    </label>
                    <DescriptionInput
                      v-model="isInputDescriptionModalVisible"
                      :title="$t('members.edit.headline.label')"
                      :description="$t('members.edit.headline.tooltip')"
                    />

                    <b-form-input
                      id="profile-headline"
                      v-model="profile.headline"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('members.edit.headline.placeholder')"
                      :disabled="isSaving"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group>
                  <!-- Type -->
                  <label for="type" class="font-weight-bold">
                    {{ $t("user-type") }}</label>
                  <v-select
                    v-model="memberType"
                    name="type"
                    :clearable="false"
                    :searchable="false"
                    label="name"
                    :placeholder="$t('form.select-placeholder')"
                    :get-option-label="(a) => getLabel(a)"
                    :options="types"
                    class="mb-2"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <!-- Summary -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('members.edit.summary.name')"
                >
                  <b-form-group
                    :label="$t('members.edit.summary.label')"
                    label-for="profile-summary"
                    class="font-weight-bold"
                  >
                    <!-- <b-form-textarea
                      id="profile-summary"
                      v-model="profile.summary"
                      rows="7"
                      class="form-control text-editor"
                      :placeholder="$t('members.edit.summary.placeholder')"
                      :disabled="isSaving"
                    /> -->
                    <PlainEditor
                      id="profile-summary"
                      v-model="profile.summary"
                      class="form-control text-editor"
                      style="height: 187px"
                      :placeholder="$t('members.edit.summary.placeholder')"
                      :disabled="isSaving"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <!-- Avatar -->
                <b-form-group>
                  <label for="logo" class="font-weight-bold">
                    {{ $t("members.edit.avatar.label") }}
                    <feather-icon
                      v-b-tooltip.hover.bottom
                      :title="`${$t('tooltips.recommended')}
                      100px x 100px`"
                      icon="InfoIcon"
                      size="14"
                      class="text-primary mt-n25"
                    />
                  </label>
                  <br>
                  <b-media-body>
                    <div>
                      <b-form-file
                        v-model="avatar"
                        accept=".jpg, .png, .gif"
                        class="pointer"
                        :placeholder="$t('form-create-item.image-placeholder')"
                        :browse-text="$t('form-create-item.image-button')"
                      />
                    </div>
                  </b-media-body>
                  <div
                    class="d-block mx-auto"
                    style="position: relative; width: 160px"
                  >
                    <b-img
                      ref="refPreviewEl"
                      :src="profile.avatarURL || logoPlaceholder"
                      width="80"
                      class="rounded my-1 mb-md-0 avatar"
                      style="
                        background: #c0c0c0;
                        min-height: 100px;
                        min-width: 150px;
                        max-height: 300px;
                      "
                    />
                    <feather-icon
                      v-if="profile.avatarURL"
                      icon="XCircleIcon"
                      size="18"
                      class="text-primary mx-auto close-icon"
                      @click="removeImg(profile.avatarURL)"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <!-- Background -->
                <b-form-group>
                  <label for="logo" class="font-weight-bold">
                    {{ $t("members.edit.background.label") }}
                    <feather-icon
                      v-b-tooltip.hover.bottom
                      :title="`${$t('tooltips.recommended')} 650px x 150px`"
                      icon="InfoIcon"
                      size="14"
                      class="text-primary mt-n25"
                    />
                  </label>
                  <br>
                  <b-media
                    no-body
                    vertical-align="bottom"
                    class="d-flex flex-wrap align-items-center"
                  >
                    <b-media-body>
                      <div>
                        <b-form-file
                          v-model="background"
                          accept=".jpg, .png, .gif"
                          class="pointer"
                          :placeholder="
                            $t('form-create-item.image-placeholder')
                          "
                          :browse-text="$t('form-create-item.image-button')"
                        />
                      </div>
                      <div
                        class="d-block mx-auto"
                        style="position: relative; width: 310px"
                      >
                        <b-img
                          ref="refPreviewEl"
                          :src="profile.backgroundURL || bannerPlaceholder"
                          width="300"
                          class="rounded my-1 mb-md-0"
                          style="
                            background: #c0c0c0;
                            min-height: 100px;
                            max-width: 300px;
                            max-height: 350px;
                          "
                        />
                        <feather-icon
                          v-if="profile.backgroundURL"
                          icon="XCircleIcon"
                          size="18"
                          class="text-primary d-block mx-auto close-icon"
                          @click="removeImg(profile.backgroundURL)"
                        />
                      </div>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>

      <!-- Languages -->
      <b-card>
        <div class="d-flex flex-wrap justify-content-between mt-2 mb-2">
          <h4>{{ $t("language") }}</h4>
          <div>
            <b-button
              variant="primary"
              type="submit"
              :disabled="isSaving"
              class="ml-1"
              @click="setLocale"
            >
              {{ $t("form.actions.save") }}
            </b-button>
          </div>
        </div>
        <Locale :display-mode="'select'" @newLocale="getNewLocale" />
      </b-card>

      <!-- Organizations -->
      <b-card>
        <div class="d-flex flex-wrap justify-content-between mt-2">
          <h4>{{ $t("members.edit.organizations.label") }}</h4>
          <div>
            <!-- Create Org Button -->
            <b-button
              v-if="staff"
              variant="primary"
              @click="isAddOrganizationModalVisible = true"
            >
              <feather-icon icon="PlusCircleIcon" size="14" />
              {{ $t("organizations.create.add-organization") }}
            </b-button>
            <!-- Save button -->
            <b-button
              variant="primary"
              type="submit"
              :disabled="isSaving"
              class="ml-1"
              @click="saveOrganizations"
            >
              {{ $t("form.actions.save") }}
            </b-button>
          </div>
        </div>
        <b-form-group class="mt-2">
          <MembersSelect
            v-model="profile.organizations"
            clearable
            multiple
            searchable
            dir="ltr"
            input-id="profile-organizations"
            class="select-multiple"
            :placeholder="$t('form.select-placeholder')"
            :fetch-data="fetchOrganizations"
            label="name"
          />
        </b-form-group>

        <!-- Modal create organization -->
        <template v-if="isAddOrganizationModalVisible">
          <b-modal
            id="modal-create-organizations"
            v-model="isAddOrganizationModalVisible"
            centered
            hide-footer
            size="lg"
          >
            <template #modal-header>
              <LanguageSelectorHeader 
                :title="$t('organizations.modal-create-title')" 
                @closeModal="closeModal" 
                @selectLanguage="(language)=>selectedLanguage = language" 
              />
            </template>
            <OrganizationCreateModal
              item-type="organizations"
              :selected="selectedLanguage"
              :joinAsMember="true"
              @close-modal="isAddOrganizationModalVisible = false"
            />
          </b-modal>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue';
import Service from '@/config/service-identifiers';
import LogoPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import BannerPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
// import Locale from '@core/layouts/components/app-navbar/components/Locale.vue';
// import PlainEditor from '@core/components/editor/PlainEditor.vue';
// import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
// import DescriptionInput from '@core/components/layouts/apps-layout/DescriptionInput.vue';
// import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
// import Links from '@/views/myself/components/Links.vue';
// import Phones from '@/views/myself/components/Phones.vue';
// import Mails from '@/views/myself/components/Mails.vue';
// import ProfileProgressBar from './ProfileProgressBar.vue';
// import OrganizationCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
// import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';

export default {
  name: 'GlobalSettings',

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    PlainEditor: () => import('@core/components/editor/PlainEditor.vue' /* webpackChunkName: "PlainEditor" */),
    DescriptionInput: () => import('@core/components/layouts/apps-layout/DescriptionInput.vue' /* webpackChunkName: "DescriptionInput" */),
    Locale: () => import('@core/layouts/components/app-navbar/components/Locale.vue' /* webpackChunkName: "Locale" */),
    MembersSelect: () => import('@/views/apps/member/components/MembersSelect.vue' /* webpackChunkName: "MembersSelect" */),
    // PlacesMapSelect: () => import('@core/components/places-map-input/PlacesMapSelect.vue' /* webpackChunkName: "PlacesMapSelect" */),
    // Links: () => import('@/views/myself/components/contact/Links.vue' /* webpackChunkName: "Links" */),
    // Phones: () => import('@/views/myself/components/contact/Phones.vue' /* webpackChunkName: "Phones" */),
    // Mails: () => import('@/views/myself/components/contact/Mails.vue' /* webpackChunkName: "Mails" */),
    ProfileProgressBar: () => import('./ProfileProgressBar.vue' /* webpackChunkName: "ProfileProgressBar" */),
    //OrganizationCreateModal: () => import('@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue' /* webpackChunkName: "OrganizationCreateModal" */),
    OrganizationCreateModal: () => import('@/views/space/components/SpaceCreateModalSimple.vue' /* webpackChunkName: "OrganizationCreateModal" */),
    LanguageSelectorHeader: () => import('@core/components/modal/LanguageSelectorHeader.vue' /* webpackChunkName: "LanguageSelectorHeader" */)
  },

  directives: { BTooltip: VBTooltip },
  mixins: [ToastNotificationsMixin],

  data() {
    return {
      member: null,
      form: {
        password: '',
        newPassword: '',
        newPassword2: '',
      },
      required,
      max,
      isStaff: false,
      send: false,
      profile: {
        avatarURL: null,
        backgroundURL: null,
        name: '',
        surname: '',
        headline: '',
        summary: '',
        organizations: null,
      },
      avatar: null,
      background: null,
      isSaving: false,
      isAddOrganizationModalVisible: false,
      isInputDescriptionModalVisible: false,
      isLoading: true,
      enabledOrganizationApp: false,
      mainOrganizations: null,
      memberType: {},
      types: [],
      newLocale: null,
      selectedLanguage: this.currentLocale,
      individualChat: false,
    };
  },

  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  
  computed: {
    ...mapGetters([
      'loggedMember',
      'parentCollective',
      'currentCollective',
    ]),

    communitySlug() {
      return this.$route.params.communityId;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    staff() {
      return this.currentCollective.isStaff;
    },
    linkLabelsOptions() {
      return ActiveLinksLabels.map((label) => ({
        text: this.$t(`links.label.${label}`),
        value: label,
      }));
    },
    avatarPlaceholder() {
      return MembersPlaceholder;
    },
    backgroundPlaceholder() {
      return HeaderPlaceholder;
    },
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return (
        !this.form.name
        || !this.form.slug
        || !this.form.visibility
        || !this.form.accessibility
      );
    },
    bannerPlaceholder() {
      return BannerPlaceholder;
    },
    logoPlaceholder() {
      return LogoPlaceholder;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
  },
  watch: {
    background(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.profile.backgroundURL = value;
            })
            .catch(() => {
              this.profile.backgroundURL = null;
            });
        } else {
          this.profile.backgroundURL = null;
        }
      }
    },
    avatar(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.profile.avatarURL = value;
            })
            .catch(() => {
              this.profile.avatarURL = null;
            });
        } else {
          this.profile.avatarURL = null;
        }
      }
    },
    member(value) {
      return value;
    },
  },
  async created() {
    this.isLoading = true;
    this.$emit('isLoading', this.isLoading);
    await this.fetchMember(this.loggedMember.username);
    await this.fetchTypes();
    this.memberType = this.member?.type ? this.member?.type : this.types.filter((item) => item.name.en === 'Member')[0];
    this.resetForm();
    await this.getInstalledApps();
    this.isLoading = false;
    this.$emit('isLoading', this.isLoading);
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-create-organizations');
    },
    setLocale() {
      if (this.newLocale) {
        this.$store.dispatch('setLocale', this.newLocale);
        this.$store.dispatch('updateUserLocale', { languageCode: this.newLocale });
        this.notifySuccess(this.$t('success-message.general-success-edit'));
      }
    },
    getNewLocale(newLocale) {
      this.newLocale = newLocale;
    },
    async getLoggedMember() {
      // await this.installedMainApps();
      const response = await this.$store.$service[Service.BackendClient].get(
        '/communityMembers',
        {
          params: {
            communitySlug:
              this.$store.getters.mainCollective?.slug
              || this.communitySlug,
            username: this.loggedMember.username,
            isForProfile: true,
          },
        },
      );
      this.mainOrganizations = response.data.data[0]?.organizations;
      this.profile.organizations = this.mainOrganizations?.map((item) => item);
    },
    async getInstalledApps() {
      if (!this.$store.getters.installedApps) {
        await this.$store.dispatch('getInstalledApps');
      }
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    resetForm() {
      this.profile = { ...this.member };
      // console.log('this.profile', this.profile);
      // console.log('this.member', this.member);
      this.avatar = null;
      this.background = null;
      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset();
      }
    },
    async fetchMember(username) {
      try {
        const spaceSlug = this.$store.getters.mainCollective?.slug ?? this.currentCollective.slug;
        // console.log('SpaceSlug:', spaceSlug);
        // console.log('username:', username);

        // TODO: use member store
        let response = await this.$store.$service[Service.BackendClient].get(
          '/communityMembers',
          {
            params: {
              communitySlug: spaceSlug,
              username,
              isForProfile: true,
            },
          },
        );
        // console.log('GlobalSettings fetchMember response1: ', response);
        // console.log('GlobalSettings fetchMember response.data.data[0]', response.data.data[0])

        const member = response.data.data[0];

        if (!member) {
          //TODO: redirect to a new error page of user not found
          this.$router.push({
            name: 'error',
            params: {
              communityId: this.$route.params.communityId,
              error: '404',
            },
          });
          return;
        }
        this.member = member;
        // console.log('We save the member data:', this.member);
      } catch (error) {
        this.hasErrors = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('error-message.load-failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async fetchOrganizations(
      page, searchString = '', force = true,
    ) {
      this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
      const requestConfigOrganizations = {
        isOrganization: true,
        communityParentSlug: this.communitySlug,
        searchString: searchString || '',
      };
      return this.$store.dispatch('getItems', {
        page,
        itemType: 'communities/simply',
        customName: 'shareOrganizations',
        storedKey: 'organizations',
        forceAPICall: force,
        perPage: this.perPage,
        requestConfig: requestConfigOrganizations,
      });
    },
    async updateProfile() {
      const requestConfig = {
        communityKey: this.currentCollective.key,
        memberKey: this.member.key,
        name: this.profile.name,
        surname: this.profile.surname,
        headline: this.profile.headline ?? '',
        summary: this.profile.summary ?? '',
        typeKey: this.memberType?.key,
        isBg: !!this.background,
        deleteBg:
          !!this.member.backgroundURL
          && !this.profile.backgroundURL
          && !this.background,
        isProfilePhoto: !!this.avatar,
        deleteProfilePhoto:
          !!this.member.avatarURL && !this.profile.avatarURL && !this.avatar,
      };

      let response = null;

      // Images:
      if (this.avatar || this.background) {
        const formData = new FormData();
        if (this.avatar) {
          formData.append('avatar', this.avatar);
        }
        if (this.background) {
          formData.append('background', this.background);
        }
        formData.append('data', JSON.stringify(requestConfig));
        response = await this.$store.$service[Service.BackendClient].put(
          'members',
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
      } else {
        response = await this.$store.$service[Service.BackendClient].put(
          'members',
          requestConfig,
        );
      }
    },
    async unsubscribeCommunity(slug) {
      await this.$store.$service[Service.BackendClient].post(
        'unsubscribeCommunityType',
        {
          communitySlug: slug,
        },
      );
      this.notifySuccess(this.$t('members.edit.success-message'));
      return this.fetchMember(this.loggedMember.username);
    },
    async onSubmit() {
      try {
        await this.updateProfile();
        this.notifySuccess(this.$t('members.edit.success-message'));
        await this.fetchMember(this.loggedMember.username);
      } catch (error) {
        console.log('error', error);
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async saveOrganizations() {
      let difference = [];
      // Remove organizations
      if (this.member?.organizations.length > 0) {
        const slugs = this.profile.organizations.map((item) => item.slug);
        difference = this.member.organizations.filter(({ slug }) => !slugs.includes(slug));
        difference.map(({ slug }) => this.unsubscribeCommunity(slug));
      }
      // Add new organizations
      let newOrganizations = [];

      const slugs = this.profile.organizations.map((item) => item.slug);
      const memberOrganizations = this.member.organizations.map(({ slug }) => slug);
      newOrganizations = slugs.filter((x) => !memberOrganizations.includes(x));
      newOrganizations.map((slug) => this.joinOrganization(slug));
      setTimeout(() => {
        this.notifySuccess(this.$t('welcome.request'));
        return this.fetchMember(this.loggedMember.username);
      }, 4000);
    },
    async joinOrganization(slug) {
      await this.$store.$service[Service.BackendClient].post(
        'requestjoinsubcommunity',
        {
          communitySlug: slug,
        },
      );
    },
    removeImg(item) {
      if (item === this.profile.avatarURL) {
        this.profile.avatarURL = null;
        this.avatar = null;
      } else {
        this.profile.backgroundURL = null;
        this.background = null;
      }
    },
    getLabel(a) {
      if (a.name) {
        return a.name[this.currentLocale]
          ? a.name[this.currentLocale]
          : Object.values(a.name)[0];
      }
    },
    async fetchTypes() {
      const response = await this.$store.$service[Service.BackendClient].get(
        'types',
        {
          params: {
            communitySlug: this.currentCollective.slug,
            getCommunityTypes: 1,
            count: 1000,
            modelType: 'member',
          },
        },
      );
      this.types = response.data.data;
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.foto {
  max-height: 150px;
  max-width: 150px;
}
.banner {
  max-height: 200px;
  max-width: 500px;
}
.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
.text-edit {
  height: 5rem !important;
}
.close-icon {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -10px;
}
</style>
